var $menuIcon = $('header .main-menu-mobile .mobile-menu-button');
var $mobileMenu = $('header .main-menu-mobile');
var $menuContent = $('header .main-menu-mobile .mobile-menu-content');
var $loginPopup = $('.login-popup');
var $logoOverlay = $('.site-logo .overlay');
var $services = $('header .desktop-menu-content ul li a');

$services.each(function () {
    var $bgColor = $(this).data('bg-color');
    var $textColor = $(this).data('text-color');
    $(this).hover(function () {
        $(this).css({
            'background-color': $bgColor,
            'color': $textColor
        });
    }, function () {
        $(this).css({
            'background-color': '#FFFFFF',
            'color': '#000'
        });
    });
});

if ($(window).width() < 1100) {
    setTimeout(function () {
        $menuContent.addClass('content-transition');
    }, 500);
}

$menuIcon.click(function () {
    if ($(this).hasClass('open')) {
        $(this).removeClass('open');
        $(this).children('p').html('Menu');
        $logoOverlay.removeClass('show');
        $mobileMenu.removeClass('menu-open');
        setTimeout(function () {
            $mobileMenu.removeClass('show');
        }, 500);
        if ($loginPopup.hasClass('show')) {
            $loginPopup.removeClass('show');
        }
        $('body').removeClass('no-scroll');
    }
    else {
        $(this).children('p').html('Close');
        $(this).addClass('open');
        $mobileMenu.addClass('show');
        setTimeout(function () {
            $mobileMenu.addClass('menu-open');
        }, 50);
        setTimeout(function () {
            $logoOverlay.addClass('show');
        }, 50);
        $('body').addClass('no-scroll');
    }
});

$('a').click(function (event) {  
    $hash = $(this).attr('href').split('#')[1];
    if($hash && window.location.hash != '' && $mobileMenu.hasClass('menu-open')) {
        $menuIcon.removeClass('open');
        $menuIcon.children('p').html('Menu');
        $logoOverlay.removeClass('show');
        $mobileMenu.removeClass('menu-open');
        setTimeout(function () {
            $mobileMenu.removeClass('show');
        }, 500);
        if ($loginPopup.hasClass('show')) {
            $loginPopup.removeClass('show');
        }
        $('body').removeClass('no-scroll');
    }
});
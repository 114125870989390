$('.hero .continue-indicator a').click(function (e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.parents('.hero');
    var $next = $parent.next('*');

    if ($next.length > 0) {
        $('html, body').animate({
            scrollTop: $next.offset().top - 50
        }, 400);
    }
});
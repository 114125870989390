var $showMore = $('.newsletter .past-issues .link-button.more');
var $showLess = $('.newsletter .past-issues .link-button.less');
var $links = $('.newsletter .past-issues .links a');
var $projectLinkColor = $('article.post-type-service').data('secondary-color');

$links.attr('data-color', $projectLinkColor);
$links.css({'text-decoration-color': $projectLinkColor});
$('article .title-and-text .links a.styled-link:after').attr('data-color', $projectLinkColor);
$('article .title-and-text .links a.styled-link').css({'text-decoration-color': $projectLinkColor});

$showMore.click(function (e) {
    e.preventDefault();

    const $currentLinks = $(this).siblings('.links').find('a');
    $currentLinks.addClass('show');
    $(this).removeClass('show');
    $(this).parent().children('.less').addClass('show');
});

$showLess.click(function (e) {
    e.preventDefault();
    const $currentLinks = $(this).siblings('.links').find('a');
    $currentLinks.removeClass('show');
    for (var i = 0; i < 3; i++) {
        $currentLinks.eq(i).addClass('show');
    }
    $(this).removeClass('show');
    $(this).parent().children('.more').addClass('show');
});
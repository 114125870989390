let $blocks = $('.title-and-text');

// Set primary color on links
let primaryColor = $('body').data('primaryColor');
if (primaryColor) {
    document.styleSheets[0].addRule('.accordion .expandable-content .accordion-text a:after', 'border-color: ' + primaryColor + ' !important');
    $('.title-and-text .text-content a').css({'text-decoration-color': primaryColor});
}

if($blocks.length > 0) {
    let $title = $('.single-service .title-and-text .circle-h2').first();
    $title.addClass("show");
    
    $blocks.each(function(index, block) {
        let $block = $(block);
        let form = $block.find($('.search-faq'));
        let $submit = $block.find('.submit-button');
        let searchInput = $block.find($('.search-input'));
        let $accordions = $block.find('.accordion');
        let $searchText;
        let $expandCollapse = $block.find('.exp-col-btn');

        let $nextBlock = $block.next();
        if($nextBlock.hasClass('title-and-text') && $nextBlock.find('.accordion').length > 0 && !$nextBlock.find('.search-faq').length > 0) {
            $accordions = $.merge($accordions, $nextBlock.find('.accordion'));
            $block = $blocks;
        }

        $expandCollapse.click(function(e) {
            if(!$expandCollapse.hasClass('active')) {
                $expandCollapse.addClass('active');
                $expandCollapse.find('span.expand').removeClass('hide');
                $expandCollapse.find('span.collapse').addClass('hide');
                //$expandCollapse.parent('.expand-collapse').addClass('collapsed');
                $block.find('.collapsable-wrapper').removeClass('open');
               // $block.find('.search-faq').removeClass('open');
            }
            else {
                $expandCollapse.removeClass('active');
                $expandCollapse.find('span.expand').addClass('hide');
                $expandCollapse.find('span.collapse').removeClass('hide');
               // $expandCollapse.parent('.expand-collapse').removeClass('collapsed');
                $block.find('.collapsable-wrapper').addClass('open');
               // $block.find('.search-faq').addClass('open');
            }
        });

        if(form) {
            form.submit(function(e) {
                e.preventDefault();
                e.stopPropagation();
            });

            $submit.click(function(e) {
                e.preventDefault();
                $searchText = searchInput.val().toLowerCase();   
                $block.find('.text-wrapper').addClass('search-active');            
                filterAccordions();
            });

            searchInput.keyup(function(e) {
                e.preventDefault();
                if(e.target.value == '') {
                    $searchText = '';
                    $block.find('.text-wrapper').removeClass('search-active');
                    filterAccordions();
                }
            });
        }

        function filterAccordions() {
            if($searchText != '') {
                $accordions.each(function(index, accordion) {
                    let $accordion = $(accordion);
                    let $nestedAccordion = $accordion.find('.nested-accordions') ?? '';
                    if(!$accordion.parent().hasClass('nested-accordions')) {
                        $accordion.removeClass('open show');
                        if($nestedAccordion) {
                            $nestedAccordion.find('.accordion').each(function(j, nested) {
                                let $nested = $(nested);
                                $nested.removeClass('open');
                            });
                        }
                    }
                    let $accordionTitle = $accordion.find('.accordion-top h3').first().text().toLowerCase() ?? '';
                    let $subtitle = $accordion.find('h5').text().toLowerCase() ?? '';
                    let $text = $accordion.find('.accordion-text').first().text().toLowerCase() ?? '';
                    let $nestedTitle = '';
                    let $nestedText = '';
                    if($nestedAccordion) {
                        $nestedTitle = $nestedAccordion.find('h3').text().toLocaleLowerCase();
                        $nestedText = $nestedAccordion.find('.accordion-text').text().toLocaleLowerCase();
                    }
                    if ($accordionTitle.indexOf($searchText) !== -1 || $subtitle.indexOf($searchText) !== -1 || $text.indexOf($searchText) !== -1 || $nestedTitle.indexOf($searchText) !== -1 || $nestedText.indexOf($searchText) !== -1) {
                        if($expandCollapse.hasClass('active')) {
                            $expandCollapse.removeClass('active');
                            $expandCollapse.find('span.expand').addClass('hide');
                            $expandCollapse.find('span.collapse').removeClass('hide');
                            $block.find('.collapsable-wrapper').addClass('open');
                        }
                        if(!$accordion.parent().hasClass('nested-accordions')) {
                            $accordion.addClass('open show');
                            if($nestedAccordion) {
                                $nestedAccordion.find('.accordion').each(function(j, nested) {
                                    let $nested = $(nested);
                                    $nested.addClass('open');
                                });
                            }
                        }
                    }
                });
    
                if($block.find('.accordion.show').length == 0) {
                    if($block.find('.results-msg').length == 0) {
                        $block.find('.collapsable-wrapper').append('<p class="results-msg">No results</p>');
                    }
                    $block.find('.results-msg').addClass('show');
                }
                else {
                    if($block.find('.results-msg').length > 0) {
                        $block.find('.results-msg').removeClass('show');
                    }
                }
            }
            else {
                $accordions.each(function(index, accordion) {
                    let $accordion = $(accordion);
                    let $nestedAccordion = $accordion.find('.nested-accordions');
                    if(!$accordion.parent().hasClass('nested-accordions')) {
                        $accordion.removeClass('open show');
                        if($nestedAccordion) {
                            $nestedAccordion.find('.accordion').each(function(j, nested) {
                                let $nested = $(nested);
                                $nested.removeClass('open');
                            });
                        }
                    }
                });
                if($block.find('.results-msg').length > 0) {
                    $block.find('.results-msg').removeClass('show');
                }
            }
        }
    });
}
window.addEventListener("scroll", hideMenu)
let isChanging = false;
var lastScrollTop = 0;

function hideMenu() {
    var $header = $('header');
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
        if (st >= 2) {
            setTimeout(function () {
                $header.css({
                    'opacity': 0
                });
            }, 100);
            setTimeout(function () {
                $header.css({
                    'pointer-events': 'none'
                });
            }, 100);
            setTimeout(function () {
                $header.removeClass('set-bg-color');
            }, 100);
        }
    } else {
        $header.css({
            'opacity': 1
        });
        $header.css({
            'pointer-events': 'auto'
        });
        $header.addClass('set-bg-color');
    }
    lastScrollTop = st <= 0 ? 0 : st;
    if (lastScrollTop == 0) {
        setTimeout(function () {
            $header.addClass('set-transparent');
        }, 100);
    }
    else {
        setTimeout(function () {
            $header.removeClass('set-transparent');
        }, 100);
    }
}
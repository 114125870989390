var $phases = $('.phases .phase');
var $phasesLength = $phases.length;
var $titleCircle = $('.phases .phases-wrapper .phase .titles .title-phase span');
var $title = $('.phases .phases-wrapper .phase .titles .title-phase');
var $serviceElem = $('.phases .phase .services .service a');

$serviceElem.each(function () {
    var $bgColor = $(this).data('bg-color');
    var $textColor = $(this).data('text-color');
    if ($bgColor.length > 0) {
        $(this).find('svg path').css({
            'fill': $bgColor
        });
        $(this).hover(function () {
            $(this).css({
                'background-color': $bgColor,
                'color': $textColor
            });
            $(this).find('svg path').css({
                'fill': $textColor
            });
        }, function () {
            $(this).css({
                'background-color': '#F2F2F1',
                'color': '#000'
            });
            $(this).find('svg path').css({
                'fill': $bgColor
            });
        });
    }
});

if ($phasesLength > 0) {
    if ($(window).width() >= 768 && $(window).width() < 1024) {
        $(window).scroll(function () {
            for (var i = 0; i < $phasesLength; i++) {
                if (($(this).scrollTop() >= ($title.eq(i).offset().top - 16)) && ($(this).scrollTop() < ($('.phases').offset().top + $('.phases').outerHeight() - 80))) {
                    $titleCircle.eq(i).css({
                        'transform': 'translateY(' + ($(this).scrollTop() - $title.eq(i).offset().top + 16) + 'px)'
                    });
                }
                else {
                    $titleCircle.eq(i).css({
                        'transform': 'translateY(0)'
                    });
                }
            }
        });
    }
    else if ($(window).width() >= 1024) {
        $(window).scroll(function () {
            for (var i = 0; i < $phasesLength; i++) {
                if (($(this).scrollTop() >= ($title.eq(i).offset().top - 24)) && ($(this).scrollTop() < ($('.phases').offset().top + $('.phases').outerHeight() - 80))) {
                    $titleCircle.eq(i).css({
                        'transform': 'translateY(' + ($(this).scrollTop() - $title.eq(i).offset().top + 24) + 'px)'
                    });
                }
                else {
                    $titleCircle.eq(i).css({
                        'transform': 'translateY(0)'
                    });
                }
            }
        });
    }
}
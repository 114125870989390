const $services = $('.help-hero .services-wrapper button');
const $container = $('.help-hero .service-support');

if($('.help-hero').length > 0) {
    if($services.length > 0) {
        $services.each(function() {
            const $bgColor = $(this).data('bg-color');
            const $textColor = $(this).data('text-color');
            $(this).hover(function() {
                $(this).css({
                    'background-color': $bgColor,
                    'color': $textColor
                });
            }, function() {
                if(!$(this).hasClass('active')) {
                    $(this).css({
                        'background-color': '#fff',
                        'color': '#000'
                    });
                }
            });
        });
    }
    
    $services.click(function() {
        const $bgColor = $(this).data('bg-color');
        const $textColor = $(this).data('text-color');
        let $post_id = $(this).attr('data-id');
        if($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).css({
                'background-color': '#fff',
                'color': '#000'
            });
            $container[0].innerHTML = '';
        }
        else {
            $services.removeClass('active');
            $services.css({
                'background-color': '#fff',
                'color': '#000'
            });
            $(this).addClass('active');
            $(this).css({
                'background-color': $bgColor,
                'color': $textColor
            });
            getSupportSection($post_id);
        }
    });
    
    function getSupportSection(post_id) {
        let data = new FormData();
        data.append('action', 'get_support_section');
        data.append('post_id', post_id);
    
        $.ajax({
            url: collabor8_scripts.ajax_url,
            type: 'POST',
            data: data,
            cache: false,
            processData: false,
            contentType: false,
            error: function (data) {
                console.error(data);
            },
            complete: function (data) {
                $container[0].innerHTML = data.responseText;
            }
        });
    }
    
    $(document).ready(function() {
        let $firstService = $('.services-wrapper button').first();
        getSupportSection($firstService.attr('data-id'));
    });
}